import React, { useEffect } from 'react'
import {
  Box,
  BoxProps,
  Flex,
  HeaderSkeleton,
  IconButton,
  Text,
  Token,
  useMatchMedia,
} from '@revolut/ui-kit'
import { Link } from 'react-router-dom'
import { css } from 'styled-components'
import useResizeObserver from 'use-resize-observer'

import { getBackUrl } from '@src/actions/RouterActions'
import mainHeaderState from '@src/features/MainHeader/MainHeaderState'
import { GlobalSearch } from '@src/features/MainHeader/GlobalSearch'
import { HelpCenter } from '@src/features/MainHeader/HelpCenter'
import { Settings } from '@src/features/MainHeader/Settings'
import { Warning } from '@src/features/MainHeader/Warning'
import { UserProfileLink } from '@src/features/MainHeader/UserProfileLink'
import Notifications from '@src/features/Notifications/Notifications'
import { PageHeaderTitle } from '@components/Page/Header/PageHeaderTitle'
import { defaultTheme } from '@src/styles/theme'
import { useBanner } from '@src/features/UIKitWithThemeProvider/BannerProvider'
import { useCoreNavigation } from '@src/features/MainLayout/useCoreNavigation'

export enum PageHeaderRouterAction {
  goBack = 'goBack',
  navigate = 'navigate',
}

export interface PageHeaderProps extends Omit<BoxProps, 'title'> {
  title: React.ReactNode
  subtitle?: React.ReactNode
  backUrl: string
  routerAction?: PageHeaderRouterAction
  children?: React.ReactNode | React.ReactNode[]
  isLoading?: boolean
  backUrlLocationState?: object
  onClickBack?: () => void
  hideGlobalSearch?: boolean
  renderAbove?: () => React.ReactNode
  renderAboveTitle?: () => React.ReactNode
  hideUpperBlock?: boolean
  noWrap?: boolean
  backButton?: React.ReactNode
  withVerticalSpacing?: boolean
}

const stickyCss = css`
  position: sticky;
  z-index: ${defaultTheme.zIndex.aboveMain + 2};
  top: 0;
`

const withDemoBarCss = css`
  top: 40px;
  margin-bottom: 24px;
`

const withDemoBarCssCoreNavigation = css`
  top: 34px;
  margin-bottom: 30px;
`

const HeaderCss = css`
  background-color: ${Token.color.layoutBackground};
  ${stickyCss}
`

export const PageHeader = ({
  title,
  subtitle,
  backUrl,
  children,
  isLoading,
  routerAction = PageHeaderRouterAction.goBack,
  backUrlLocationState,
  onClickBack,
  hideGlobalSearch,
  hideUpperBlock,
  renderAbove,
  renderAboveTitle,
  noWrap = true,
  backButton,
  withVerticalSpacing = false,
  ...props
}: PageHeaderProps) => {
  const { height: headerHeight, ref } = useResizeObserver()
  const mdMedia = useMatchMedia(Token.media.md)
  const isCoreNavigation = useCoreNavigation()

  const { visible } = useBanner()

  useEffect(() => {
    if (headerHeight) {
      mainHeaderState.height = headerHeight + 26 + (visible ? 40 : 0)
    }
  }, [headerHeight, visible])

  const routesBack = routerAction === PageHeaderRouterAction.goBack

  return isLoading ? (
    <HeaderSkeleton variant="item" labelBackButton="Back" />
  ) : (
    <>
      <Box
        {...(isCoreNavigation
          ? { pt: mdMedia ? 's-24' : 's-64', pb: 's-6' }
          : withVerticalSpacing
          ? { pt: 's-6', pb: 's-6' }
          : { pt: 's-20', pb: 's-6' })}
        {...props}
        ref={ref}
        css={[
          HeaderCss,
          visible
            ? isCoreNavigation
              ? withDemoBarCssCoreNavigation
              : withDemoBarCss
            : null,
        ]}
      >
        {renderAbove?.()}
        {!hideUpperBlock && (
          <Flex
            alignItems="center"
            height={isCoreNavigation ? 40 : withVerticalSpacing ? 44 : 40}
          >
            {backButton || (
              <IconButton
                size={24}
                useIcon={routesBack ? 'BackButtonArrow' : 'Cross'}
                aria-label="close"
                color="foreground"
                use={onClickBack ? undefined : Link}
                onClick={onClickBack}
                // @ts-expect-error object works fine here, but UI kit expects string
                to={routesBack ? getBackUrl(backUrl, backUrlLocationState) : backUrl}
              />
            )}

            {isCoreNavigation ? null : (
              <>
                {/* TODO: https://revolut.atlassian.net/browse/REVCOR-2756 remove and get rid of all the components and `hideGlobalSearch` prop */}
                <Flex flex={1} />
                {hideGlobalSearch ? null : (
                  <Flex alignItems="center" gap="s-8">
                    <GlobalSearch />
                    <Notifications />
                    <HelpCenter />
                    <Settings />
                    <Warning />
                    <UserProfileLink />
                  </Flex>
                )}
              </>
            )}
          </Flex>
        )}
      </Box>

      <Box>
        {renderAboveTitle?.()}
        <Text
          use="h1"
          variant="h1"
          whiteSpace={noWrap ? 'nowrap' : undefined}
          textOverflow="ellipsis"
          overflow="hidden"
          mb="s-4"
          data-testid="header_title"
        >
          {title}
        </Text>

        <Box>
          <Text
            mb="s-16"
            color="grey-tone-50"
            fontSize="14px"
            whiteSpace={noWrap ? 'nowrap' : undefined}
            textOverflow="ellipsis"
            overflow="hidden"
            lineHeight="22px"
            use="div"
            data-testid="header-subtitle"
          >
            {subtitle}
          </Text>
        </Box>
        {!isLoading && children}
      </Box>
    </>
  )
}

PageHeader.Title = PageHeaderTitle
